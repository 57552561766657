import "swiper/css";
import { A11y, Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { MainButton } from "../shared/MainButton";

export const HowToPlay = () => {
  return (
    <>
      <div className="w-full h-full text-center">
        <p className="text-[#B2B2B2] text-[14px] leading-[14px]">How To Play</p>
        <p className="text-white text-[32px] leading-[40px]">What is thumba?</p>
      </div>

      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log("Swiper initialized", swiper)}
        className="w-full"
      >
        <SwiperSlide className="p-4 text-center">
          <div className="bg-[#0B0B0B] border border-[#313131] p-6 min-h-[500px]">
            <p className="text-[#B2B2B2] font-[14px] leading-[14px]">1</p>
            <p className="text-white font-[24px] leading-[24px]">Pick a Player</p>
            <img src="/how-to-play/step01.png" alt="Pick a Player" className="w-full my-6" />
            <p className="text-white font-[24px] leading-[24px] mb-[10px]">fast and easy</p>
            <p className="text-[#B2B2B2] font-[14px] leading-[20px] height-[40px]">
              Choose a player which will play
              <br />
              better in the match
            </p>
            <div className="flex justify-center items-center gap-1 my-6">
              <div className="w-[50px] h-[2px] bg-gradient-to-r from-[#00E218] to-[#FFE500]" />
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
            </div>
            <div className="mt-6 flex justify-center items-center">
              <a href="https://t.me/PreThumbabot">
                <MainButton>
                  <img src="/telegram-black.svg" alt="Play" className="h-5" />
                  "Pre-registration now open!!"
                </MainButton>
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="p-4 text-center">
          <div className="bg-[#0B0B0B] border border-[#313131] p-6 min-h-[500px]">
            <p className="text-[#B2B2B2] font-[14px] leading-[14px]">2</p>
            <p className="text-white font-[24px] leading-[24px]">Get points for the real world</p>
            <img src="/how-to-play/step02.png" alt="Pick a Player" className="w-full my-6" />
            <p className="text-white font-[24px] leading-[24px] mb-[10px]">Performance of your players</p>
            <p className="text-[#B2B2B2] font-[14px] leading-[20px] height-[40px]">
              Points are set in detail for assists
              <br />
              and passes other than goals.
            </p>
            <div className="flex justify-center items-center gap-1 my-6">
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
              <div className="w-[50px] h-[2px] bg-gradient-to-r from-[#00E218] to-[#FFE500]" />
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
            </div>
            <div className="mt-6 flex justify-center items-center">
              <a href="https://t.me/PreThumbabot">
                <MainButton>
                  <img src="/telegram-black.svg" alt="Play" className="h-5" />
                  "Pre-registration now open!!"
                </MainButton>
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="p-4 text-center">
          <div className="bg-[#0B0B0B] border border-[#313131] p-6 min-h-[500px]">
            <p className="text-[#B2B2B2] font-[14px] leading-[14px]">3</p>
            <p className="text-white font-[24px] leading-[24px]">Play tournaments</p>
            <img src="/how-to-play/step03.png" alt="Pick a Player" className="w-full my-6" />
            <p className="text-white font-[24px] leading-[24px] mb-[10px]">Numerous players are awaiting</p>
            <p className="text-[#B2B2B2] font-[14px] leading-[20px] height-[40px]">compete with other players</p>
            <div className="flex justify-center items-center gap-1 my-6">
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
              <div className="w-[50px] h-[2px] bg-gradient-to-r from-[#00E218] to-[#FFE500]" />
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
            </div>
            <div className="mt-6 flex justify-center items-center">
              <a href="https://t.me/PreThumbabot">
                <MainButton>
                  <img src="/telegram-black.svg" alt="Play" className="h-5" />
                  "Pre-registration now open!!"
                </MainButton>
              </a>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="p-4 text-center">
          <div className="bg-[#0B0B0B] border border-[#313131] p-6 min-h-[500px]">
            <p className="text-[#B2B2B2] font-[14px] leading-[14px]">4</p>
            <p className="text-white font-[24px] leading-[24px] mb-[10px]">Be a star</p>
            <img src="/how-to-play/step04.png" alt="Pick a Player" className="w-full my-6" />
            <p className="text-white font-[24px] leading-[24px]">Chance of winning</p>
            <p className="text-[#B2B2B2] font-[14px] leading-[20px] height-[40px]">
              Pay minimum amount of money
              <br />
              and be a billionaire
            </p>
            <div className="flex justify-center items-center gap-1 my-6">
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
              <div className="w-[50px] h-[2px] bg-[#4F4F4F]" />
              <div className="w-[50px] h-[2px] bg-gradient-to-r from-[#00E218] to-[#FFE500]" />
            </div>
            <div className="mt-6 flex justify-center items-center">
              <a href="https://t.me/PreThumbabot">
                <MainButton>
                  <img src="/telegram-black.svg" alt="Play" className="h-5" />
                  "Pre-registration now open!!"
                </MainButton>
              </a>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="swiper-button-prev" />
      <div className="swiper-button-next" />
      <div className="swiper-scrollbar" />
    </>
  );
};
