import ReactGA from "react-ga4";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { EmulateTwaPage } from "./pages/EmulateTwaPage";
import { HomePage } from "./pages/Home";
import { TwaPage } from "./pages/Twa";

const App = () => {
  ReactGA.initialize("G-H5SWYMDGX4");

  return (
    <Router basename="/">
      <Routes>
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="/twa" element={<TwaPage />} />
          <Route path="/emulate-twa" element={<EmulateTwaPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
