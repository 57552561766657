interface Props {
  toggleKeyVisual: () => void;
}

export const Header: React.FC<Props> = ({ toggleKeyVisual }) => (
  <header className="flex flex-row items-center justify-between h-18 pt-10 pb-[15px] px-4 fixed top-0 left-0 right-0 bg-black z-50 md:hidden">
    <div className="flex flex-row items-center justify-between gap-3">
      {/* <img src="/menu.svg" alt="Menu" className="h-5" /> */}
      <button type="button" onClick={toggleKeyVisual}>
        <img src="/logo.svg" alt="Thumba" className="h-5" id="logo" />
      </button>
    </div>
    <div className="flex flex-row items-center justify-between gap-3">
      <a href="https://t.me/+7siGL7Uhq9gyNmU1">
        <img src="/telegram.svg" alt="Search" className="h-5" />
      </a>
      <a href="https://x.com/Thumba_official">
        <img src="/x.svg" alt="User" className="h-5" />
      </a>
      <a href="https://t.me/ThumbaSupportBot">
        <img src="/chat.svg" alt="FAQ" className="h-5" />
      </a>
    </div>
  </header>
);
