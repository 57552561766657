import { SubButton } from "../shared/SubButton";

interface Props {
  openImportantNotes: () => void;
}

export const Reward: React.FC<Props> = ({ openImportantNotes }) => (
  <div className="relative w-full">
    <div className="absolute inset-0 bg-gradient-to-l from-[#00E218] to-[#FFE500] opacity-75" />
    <div className="absolute inset-0 bg-[url('/pc-reward-bg.png')] bg-no-repeat bg-contain bg-top" />

    <div className="relative z-10 p-[26px] text-center">
      <div className="w-[1000px] mx-auto">
        <p className="text-black text-[24px] leading-[24px]">Rewards Overview</p>
        <p className="text-black text-[96px] leading-[64px] my-[30px]">
          <img src="/ton.svg" alt="icon01" className="w-10 h-10 inline-block" />
          2000<span className="text-[28px] ml-2">TON</span>
        </p>

        <div className="flex justify-center">
          <div className="w-1/2 px-4">
            <div className="w-full flex items-start h-20">
              <img src="/ton.svg" alt="icon01" className="w-[46px] h-[46px] mr-3" />
              <span className="text-[64px] leading-[48px]">1000</span>
              <span className="text-[28px] ml-2">TON</span>
              <p className="text-black text-[20px] leading-[46px] text-left ml-3">Airdrop by raffle</p>
            </div>

            <div className="w-full relative h-[65px] mt-[20px]">
              <p className="text-gradient text-[24px] leading-[55px] h-[55px] absolute inset-x-0 top-[10px] z-10 m-0">
                Registration campaign
              </p>
              <img
                src="/bg-registration-campaign.svg"
                alt="reward"
                className="w-full absolute top-0 left-0 z-0 h-[65px]"
              />
            </div>

            <div className="mx-[18px] border border-[#E5E5E5]">
              <div className="flex flex-raw justify-around items-center text-black text-[14px] leading-[28px] bg-white border-b border-[#E5E5E5]">
                <p>number of WINNERS</p>
                <p>Distribution amount</p>
              </div>

              <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
                <p className="text-center w-[45%]">1</p>
                <span className="angled-line w-[10%]" />
                <p className="text-center w-[45%]">
                  <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
                  500
                </p>
              </div>
              <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
                <p className="text-center w-[45%]">2</p>
                <span className="angled-line w-[10%]" />
                <p className="text-center w-[45%]">
                  <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
                  100×2
                </p>
              </div>
              <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
                <p className="text-center w-[45%]">4</p>
                <span className="angled-line w-[10%]" />
                <p className="text-center w-[45%]">
                  <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
                  50×4
                </p>
              </div>
              <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
                <p className="text-center w-[45%]">5</p>
                <span className="angled-line w-[10%]" />
                <p className="text-center w-[45%]">
                  <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
                  10×5
                </p>
              </div>
              <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
                <p className="text-center w-[45%]">10</p>
                <span className="angled-line w-[10%]" />
                <p className="text-center w-[45%]">
                  <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
                  5×10
                </p>
              </div>
            </div>
          </div>
          <div className="w-1/2 px-4">
            <div className="w-full flex h-20">
              <img src="/ton.svg" alt="icon01" className="w-[46px] h-[46px] mr-3 ml-[-20px]" />
              <span className="text-[64px] leading-[48px]">1000</span>
              <span className="text-[28px] ml-2">TON</span>
              <p className="text-black text-[20px] leading-[20px] text-left ml-3">
                Airdrop by raffle or GUARANTEED
                <br />
                <span className="text-black/60 text-[14px] leading-[14px]">
                  For every 5, 10, or 20 people you invite, you will be eligible to participate in a prize draw based on
                  the number of invites.
                </span>
              </p>
            </div>

            <div className="w-full relative h-[65px] mt-[20px]">
              <p className="text-gradient text-[24px] leading-[55px] h-[55px] absolute inset-x-0 top-[10px] z-10 m-0">
                Registration campaign
              </p>
              <img
                src="/bg-registration-campaign.svg"
                alt="reward"
                className="w-full absolute top-0 left-0 z-0 h-[65px]"
              />
            </div>

            <div className="mx-[18px] border border-[#E5E5E5]">
              <div className="flex flex-raw justify-around items-center text-black text-[14px] leading-[28px] bg-white border-b border-[#E5E5E5]">
                <p>number of WINNERS</p>
                <p>Distribution amount</p>
              </div>

              <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
                <p className="text-center w-[45%]">1</p>
                <span className="angled-line w-[10%]" />
                <p className="text-center w-[45%]">
                  <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
                  500
                </p>
              </div>
              <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
                <p className="text-center w-[45%]">2</p>
                <span className="angled-line w-[10%]" />
                <p className="text-center w-[45%]">
                  <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
                  100×2
                </p>
              </div>
              <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
                <p className="text-center w-[45%]">4</p>
                <span className="angled-line w-[10%]" />
                <p className="text-center w-[45%]">
                  <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
                  50×4
                </p>
              </div>
              <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
                <p className="text-center w-[45%]">5</p>
                <span className="angled-line w-[10%]" />
                <p className="text-center w-[45%]">
                  <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
                  10×5
                </p>
              </div>
              <div className="flex flex-row justify-around items-center text-black text-[20px] leading-[36px] bg-white border-b border-[#E5E5E5]">
                <p className="text-center w-[45%]">10</p>
                <span className="angled-line w-[10%]" />
                <p className="text-center w-[45%]">
                  <img src="/ton.svg" alt="icon01" className="w-5 h-5 inline-block mr-2" />
                  5×10
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-raw justify-center items-center text-black text-[14px] leading-[20px]">
          <p className="text-black text-[16px] leading-[20px] pt-4 pb-2">
            Special rewards may be revealed at each milestone of
            <br />
            10K, 50K, 100K, 150K, and 200K subscribers.
          </p>
          <img src="/present-box 1.png" alt="reward" className="w-15 h-15 ml-4 my-4" />
        </div>
        <SubButton className="mx-auto" onClick={openImportantNotes} height="35px">
          ※Important notes
        </SubButton>
      </div>
    </div>
  </div>
);
