interface Props {
  className?: string;
  width?: string;
  height?: string;
  children: React.ReactNode;
  onClick?: () => void;
  href?: string;
}

export const MainButton: React.FC<Props> = ({ width = "276px", height = "55px", className, children, onClick }) => {
  return (
    <button
      type="button"
      className={`w-[${width}] h-[${height}] text-lg text-black font-bold px-4 py-2 rounded-full bg-gradient-to-r to-malachite-500 from-turbo-500 flex items-center justify-center gap-2 ${className}`}
      onClick={() => onClick?.()}
    >
      {children}
    </button>
  );
};

export const MainLinkButton: React.FC<Props> = ({ width = "276px", height = "55px", className, children, href }) => {
  return (
    <a
      type="button"
      className={`w-[${width}] h-[${height}] text-lg text-black font-bold px-4 py-2 rounded-full bg-gradient-to-r to-malachite-500 from-turbo-500 flex items-center justify-center gap-2 ${className}`}
      href={href}
    >
      {children}
    </a>
  );
};
