import { useUserCount } from "@/hooks/useUserCount";
import { MainButton } from "../shared/MainButton";

interface Props {
  currentKeyVisualIndex: number;
}

export const KeyVisual: React.FC<Props> = ({ currentKeyVisualIndex }) => {
  const keyVisualUrl = `/key-visual-0${currentKeyVisualIndex}.png`;
  const { userCount } = useUserCount();

  const scrollToCampaign = () => {
    const element = document.getElementById("campaign-mobile");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <p className="text-white text-[28px] leading-[35px] mx-[30px]">
        Enter the exciting world
        <br />
        of fantasy sports
        <br />
        with your thumb
      </p>
      <div
        className="bg-cover bg-center bg-no-repeat w-full h-[calc(100vw*560/390)] relative"
        style={{ backgroundImage: `url('${keyVisualUrl}')` }}
      >
        <div className="absolute bottom-[27px] left-[30px]">
          <a href="https://t.me/PreThumbabot">
            <MainButton>
              <img src="/telegram-black.svg" alt="Play" className="h-5" />
              "Pre-registration now open!!"
            </MainButton>
          </a>
          <button
            className="text-[#989898] rounded-full text-[16px] leading-[35px] w-40 bg-[#2A2A2A] mt-[10px]"
            type="button"
            onClick={scrollToCampaign}
          >
            ※Details
          </button>
          {userCount != null && (
            <>
              <p className="text-[#989898] text-[14px] leading-[14px] mt-4 mb-1">Pre-registration users​</p>
              <p className="text-white text-[46px] leading-[46px]">{userCount.toLocaleString()}</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
