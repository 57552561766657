import { MainButton } from "../shared/MainButton";

export const Header = () => (
  <>
    <header className="fixed top-0 left-0 right-0 bg-black z-50 md:block hidden">
      <div className="flex flex-row items-center justify-between max-w-[1200px] mx-auto h-20">
        <div className="flex flex-row items-center justify-between gap-6">
          <img src="/logo.svg" alt="Thumba" className="h-5" id="logo" />
          {/* <p className="text-[#989898]">HOME</p> */}
          {/* <p className="text-[#989898]">FAQ</p> */}
        </div>
        <div className="flex flex-row items-center justify-between gap-6">
          <a href="https://t.me/+7siGL7Uhq9gyNmU1">
            <img src="/telegram.svg" alt="Search" className="h-5" />
          </a>
          <a href="https://x.com/Thumba_official">
            <img src="/x.svg" alt="User" className="h-5" />
          </a>
          <a href="https://t.me/ThumbaSupportBot">
            <img src="/chat.svg" alt="FAQ" className="h-5" />
          </a>
          <a href="https://t.me/PreThumbabot">
            <MainButton height="35px" className="px-[20px]">
              Pre-registration now open
            </MainButton>
          </a>
        </div>
      </div>
    </header>
  </>
);
