import "swiper/css";
import { A11y, Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export const Content = () => (
  <>
    <div className="w-full h-full text-center mt-10">
      <p className="text-white text-[24px] leading-[24px]">Contents</p>
    </div>

    <Swiper
      modules={[Navigation, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log("Swiper initialized", swiper)}
      className="w-full"
    >
      <SwiperSlide className="p-4 text-center">
        <img src="/contents/content01.png" alt="soccer" className="w-full my-6" />
      </SwiperSlide>
      <SwiperSlide className="p-4 text-center">
        <img src="/contents/content02.png" alt="nba" className="w-full my-6" />
      </SwiperSlide>
      <SwiperSlide className="p-4 text-center">
        <img src="/contents/content03.png" alt="MLB" className="w-full my-6" />
      </SwiperSlide>
      <SwiperSlide className="p-4 text-center">
        <img src="/contents/content04.png" alt="F1" className="w-full my-6" />
      </SwiperSlide>
    </Swiper>
  </>
);
