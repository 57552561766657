import { useState } from "react";
import { Sports } from "../shared/Sports";
import { Campaign } from "./Campaign";
import { Content } from "./Content";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { HowToPlay } from "./HowToPlay";
import { KeyVisual } from "./KeyVisual";
import { Reward } from "./Reward";

interface Props {
  openImportantNotes: () => void;
}

export const MobileLayout: React.FC<Props> = ({ openImportantNotes }) => {
  const [currentKeyVisualIndex, setCurrentKeyVisualIndex] = useState(1);
  const toggleKeyVisual = () => {
    setCurrentKeyVisualIndex((prev) => (prev === 1 ? 2 : 1));
  };

  return (
    <>
      <Header toggleKeyVisual={toggleKeyVisual} />
      <main className="pt-[132px] pb-[50px] md:hidden block">
        <KeyVisual currentKeyVisualIndex={currentKeyVisualIndex} />
        <HowToPlay />
        <Content />
        <Sports />
        <Campaign />
        <Reward openImportantNotes={openImportantNotes} />
        <Footer />
      </main>
    </>
  );
};
