import { useState } from "react";
import { MainButton } from "../shared/MainButton";
import { SubButton } from "../shared/SubButton";

export const Campaign = () => {
  const [currentTab, setCurrentTab] = useState<"main" | "sub">("main");
  return (
    <>
      <div className="w-full text-center" id="campaign-mobile">
        <div className="mt-10 mx-4 bg-gradient-to-l from-[#00E218] to-[#FFE500]">
          <div className="py-2 px-4">
            <p className="text-black text-[18px] leading-[26px] mt-[14px]">Pre-Registration Campaign Overview</p>
            <p className="text-black text-[40px] leading-[40px] my-[10px]">
              <img src="/ton.svg" alt="icon01" className="w-10 h-10 inline-block" />
              2000<span className="text-[28px] ml-2">TON</span>
            </p>
            <p className="text-black text-[18px] leading-[26px] mb-[14px]">
              AIRDROP!!​
              <br />
              Join this campaign and earn free Ton!!​
            </p>
          </div>

          <div className="w-full p-[1px]">
            <div className="w-[calc(100%-2px)] bg-[#000000]/80 border border-transparent py-[10px]">
              <span className="text-gradient text-[18px] leading-[26px]">Pre-Registration 9/9 ~ Could be tomorrow</span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-left">
        <div className="mb-10 mx-4 border border-[#313131] p-[22px]">
          <p className="text-center text-[#989898]">How to Participate</p>

          <div className="w-full flex my-4 justify-around gap-2">
            <button
              type="button"
              onClick={() => setCurrentTab("main")}
              className={`border-b text-[24px] leading-[35px] w-1/2 ${
                currentTab === "main" ? "text-white border-gradient" : "text-[#545454] border-[#545454]"
              }`}
            >
              main
            </button>
            <button
              type="button"
              onClick={() => setCurrentTab("sub")}
              className={`border-b text-[18px] leading-[26px] w-1/2 ${
                currentTab === "sub" ? "text-white border-gradient" : "text-[#545454] border-[#545454]"
              }`}
            >
              Referral
            </button>
          </div>

          {currentTab === "main" ? (
            <>
              <p className="text-white text-[20px] leading-[35px] pt-4">Pre-Registration term​</p>

              <p className="text-gradient text-[18px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">1.</span> Join the Campaign
              </p>
              <a href="https://t.me/PreThumbabot">
                <MainButton className="w-full">
                  <img src="/telegram-black.svg" alt="Play" className="h-5" />
                  THUMBA bot​
                </MainButton>
              </a>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">2.</span> Join the Telegram community
              </p>
              <a href="https://t.me/+7siGL7Uhq9gyNmU1">
                <SubButton className="w-full">
                  <img src="/telegram-white.svg" alt="Play" className="h-5" />
                  Thumba official
                </SubButton>
              </a>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">3.</span> Follow Thumba X Account
              </p>
              <a href="https://x.com/Thumba_official">
                <SubButton className="w-full">
                  <img src="/x-black.svg" alt="X" className="w-[18px] h-[18px] inline-block" />
                  Thumba X
                </SubButton>
              </a>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">4.</span> Like and RT our X tweet
              </p>
              <p>
                <img src="/x-black.svg" alt="X" className="w-[18px] h-[18px] inline-block" />
                <span className="text-[#545454] text-[14px] leading-[18px] ml-2">
                  https://x.com/Thumba_official/status/1833373270462369910
                </span>
              </p>

              <p className="pt-4 text-white text-[20px] leading-[35px]">
                Service Released
                <span className="text-[#545454] text-[14px] leading-[35px]">（How to achieve Airdrops）​</span>
              </p>
              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">5.</span> Participate Finish the tutorial in Thumba
              </p>
              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">6.</span> Connect Wallet
              </p>
            </>
          ) : (
            <>
              <p className="text-white text-[20px] leading-[35px] pt-4">Pre-Registration term​</p>

              <p className="text-gradient text-[18px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">1.</span> Join the Campaign
              </p>

              <a href="https://t.me/PreThumbabot">
                <MainButton className="w-full">
                  <img src="/telegram-black.svg" alt="Play" className="h-5" />
                  THUMBA bot​
                </MainButton>
              </a>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[#545454]">2.</span> Invite friends to the Campaign
              </p>
              <p className="text-[#989898]/50 text-[12px] leading-[16px] text-left mb-4">
                Share link to this campaign with your friends and followers on telegram, X, etc...
              </p>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[#545454]">3.</span> Friends Complete main Campaign
              </p>
              <p className="text-[#989898] text-[14px] leading-[18px] text-left mb-4">
                ※The numbers of the friends you've invited to the CAMPAIGN will be counted
              </p>
              <p className="text-[#989898] text-[14px] leading-[18px] text-left mb-4">
                ※For every 5, 10, or 20 people you invite, you will be eligible to participate in a prize draw based on
                the number of invites. Additionally, the top 1st and 2nd referrers are guaranteed to receive rewards.
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
