import { MainButton } from "../shared/MainButton";

export const Footer = () => (
  <div className="flex flex-col items-center pt-[20px] pb-[60px]">
    <a href="https://t.me/PreThumbabot">
      <MainButton className="mb-[50px]">
        <img src="/telegram-black.svg" alt="Play" className="h-5" />
        "Pre-registration now open!!"​
      </MainButton>
    </a>

    <div className="flex flex-raw justify-around mb-[20px] gap-4">
      <p className="text-gradient text-[18px] leading-[24px]">
        <span className="text-[#989898]">©</span>DraftBeasts Inc.
      </p>
      {/* <p className="text-[#989898] text-[14px] leading-[24px]">privacy policy</p> */}
    </div>

    <div className="flex flex-raw justify-center gap-4">
      <a href="https://t.me/+7siGL7Uhq9gyNmU1">
        <img src="/telegram.svg" alt="Search" className="h-5" />
      </a>
      <a href="https://x.com/Thumba_official">
        <img src="/x.svg" alt="User" className="h-5" />
      </a>
      <a href="https://t.me/ThumbaSupportBot">
        <img src="/chat.svg" alt="FAQ" className="h-5" />
      </a>
    </div>
  </div>
);
