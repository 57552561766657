import { MainButton } from "../shared/MainButton";
import { SubButton } from "../shared/SubButton";

export const Campaign = () => (
  <>
    <div id="campaign" />
    <div className="w-full max-w-[1140px] px-30 mx-auto">
      <div className="mt-10 mx-4 bg-gradient-to-l from-[#00E218] to-[#FFE500]">
        <div className="py-2 px-4 flex flex-row items-center justify-center gap-8">
          <div>
            <p className="text-black text-[32px] leading-[26px] mb-[14px]">Pre-Registration Campaign Overview</p>
            <p className="text-black text-[18px] leading-[16px]">
              Give away!!​Participate this campaign and earn free Ton!!​
            </p>
          </div>
          <p className="text-black text-8xl align-middle mt-[10px]">
            <img src="/ton.svg" alt="icon01" className="w-16 h-16 inline-block" />
            <span className="ml-2">2000</span>
            <span className="text-[28px] ml-2">TON</span>
          </p>
        </div>

        <div className="w-full p-[1px] text-center">
          <div className="w-[calc(100%-2px)] bg-[#000000]/80 border border-transparent py-[10px]">
            <span className="text-gradient text-[18px] leading-[26px]">Pre-Registration 9/9 ~ Could be tomorrow</span>
          </div>
        </div>
      </div>
    </div>

    <div className="w-full  max-w-[1140px] px-30 mx-auto">
      <div className="mb-10 mx-4 border border-[#313131] p-[22px]">
        <div className="max-w-[720px] mx-auto">
          <p className="text-center text-[#989898] text-[24px] leading-[24px] mb-4">How to Participate</p>
          <div className="flex justify-center">
            <div className="w-1/2 px-4">
              <h3 className="text-white text-[40px] mt-2 leading-[35px] text-left">Main</h3>
              <p className="text-white text-[26px] mt-2 leading-[35px] text-left">Pre-Registration term​</p>

              <p className="text-gradient text-[18px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">1.</span> Join the Campaign
              </p>
              <a href="https://t.me/PreThumbabot">
                <MainButton className="w-full">
                  <img src="/telegram-black.svg" alt="Play" className="h-5" />
                  THUMBA bot​
                </MainButton>
              </a>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">2.</span> Join the Telegram community
              </p>
              <a href="https://t.me/+7siGL7Uhq9gyNmU1">
                <SubButton className="w-full">
                  <img src="/telegram-white.svg" alt="Play" className="h-5" />
                  Thumba official
                </SubButton>
              </a>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">3.</span> Follow Thumba X Account
              </p>
              <a href="https://x.com/Thumba_official">
                <SubButton className="w-full">
                  <img src="/x-black.svg" alt="X" className="w-[18px] h-[18px] inline-block" />
                  Thumba X
                </SubButton>
              </a>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">4.</span> Like and RT our X tweet
              </p>
              <p>
                <img src="/x-black.svg" alt="X" className="w-[18px] h-[18px] inline-block" />
                <span className="text-[#545454] text-[14px] leading-[18px] ml-2">
                  https://x.com/Thumba_official/status/1833373270462369910
                </span>
              </p>

              <p className="pt-4 text-white text-[20px] leading-[35px]">
                Service Released
                <span className="text-[#545454] text-[14px] leading-[35px]">（How to achieve Airdrops）​</span>
              </p>
              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">5.</span> Participate Finish the tutorial in Thumba
              </p>
              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">6.</span> Connect Wallet
              </p>
            </div>

            <div className="w-1/2 px-4">
              <h3 className="text-white text-[40px] mt-2 leading-[35px] text-left">Referral</h3>
              <p className="text-white text-[26px] mt-2 leading-[35px] text-left">Pre-Registration term​</p>
              <p className="text-gradient text-[18px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">1.</span> Join the Campaign
              </p>
              <a href="https://t.me/PreThumbabot">
                <MainButton className="w-full">
                  <img src="/telegram-black.svg" alt="Play" className="h-5" />
                  THUMBA bot​
                </MainButton>
              </a>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">2.</span> Generate an invitation link via Pre-Registration bot
              </p>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                <span className="text-[545454]">2.</span> Invite friends to the Campaign
              </p>

              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                ※The numbers of the friends you've invited to the CAMPAIGN will be counted
              </p>
              <p className="text-[#989898] text-[14px] leading-[18px] pt-4 pb-2">
                ※For every 5, 10, or 20 people you invite, you will be eligible to participate in a prize draw based on
                the number of invites. Additionally, the top 1st and 2nd referrers are guaranteed to receive rewards.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
