import { MainButton } from "../shared/MainButton";

export const HowToPlay = () => (
  <>
    <div className="w-full h-full text-center max-w-[1300px] px-[30px] mx-auto my-8">
      <p className="text-[#B2B2B2] text-[14px] leading-[14px]">How To Play</p>
      <p className="text-white text-[32px] leading-[40px]">What is thumba?</p>
    </div>

    <div className="w-full max-w-[1300px] px-[30px] mx-auto flex justify-between gap-4">
      <div className="bg-[#0B0B0B] border border-[#313131] p-6 h-[400px] text-center">
        <p className="text-[#B2B2B2] font-[14px] leading-[14px]">1</p>
        <p className="text-white font-[24px] leading-[24px]">Pick a Player</p>
        <img src="/how-to-play/step01.png" alt="Pick a Player" className="w-full my-6" />

        <p className="text-white font-[24px] leading-[24px] mb-[10px]">fast and easy</p>
        <p className="text-[#B2B2B2] font-[14px] leading-[20px]">
          Choose a player which will play
          <br />
          better in the match
        </p>
      </div>

      <div className="bg-[#0B0B0B] border border-[#313131] p-6 h-[400px] text-center">
        <p className="text-[#B2B2B2] font-[14px] leading-[14px]">2</p>
        <p className="text-white font-[24px] leading-[24px]">Get points for the real world</p>
        <img src="/how-to-play/step02.png" alt="Pick a Player" className="w-full my-6" />

        <p className="text-white font-[24px] leading-[24px]">Performance of your players</p>
        <p className="text-[#B2B2B2] font-[14px] leading-[20px]">
          Points are set in detail for assists
          <br />
          and passes other than goals.
        </p>
      </div>

      <div className="bg-[#0B0B0B] border border-[#313131] p-6 h-[400px] text-center">
        <p className="text-[#B2B2B2] font-[14px] leading-[14px]">3</p>
        <p className="text-white font-[24px] leading-[24px]">Play tournaments</p>
        <img src="/how-to-play/step03.png" alt="Pick a Player" className="w-full my-6" />

        <p className="text-white font-[24px] leading-[24px]">Numerous players are awaiting</p>
        <p className="text-[#B2B2B2] font-[14px] leading-[20px]">compete with other players</p>
      </div>

      <div className="bg-[#0B0B0B] border border-[#313131] p-6 h-[400px] text-center">
        <p className="text-[#B2B2B2] font-[14px] leading-[14px]">4</p>
        <p className="text-white font-[24px] leading-[24px]">Be a star</p>
        <img src="/how-to-play/step04.png" alt="Pick a Player" className="w-full my-6" />

        <p className="text-white font-[24px] leading-[24px]">Chance of winning</p>

        <p className="text-[#B2B2B2] font-[14px] leading-[20px]">
          Pay minimum amount of money
          <br />
          and be a billionaire
        </p>
      </div>
    </div>

    <div className="w-full h-full text-center max-w-[1360px] mx-auto my-8">
      <a href="https://t.me/PreThumbabot">
        <MainButton width="100%" className="max-w-[1280px] w-4/5 mx-auto">
          <img src="/telegram-black.svg" alt="Play" className="h-5" />
          "Pre-registration now open!!"​
        </MainButton>
      </a>
    </div>
  </>
);
