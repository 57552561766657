import { Sports } from "../shared/Sports";
import { Campaign } from "./Campaign";
import { Content } from "./Content";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { HowToPlay } from "./HowToPlay";
import { KeyVisual } from "./KeyVisual";
import { Reward } from "./Reward";

interface Props {
  openImportantNotes: () => void;
}

export const PCLayout: React.FC<Props> = ({ openImportantNotes }) => {
  const goToCampaign = () => {
    const targetElement = document.getElementById("campaign");
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Header />
      <main className="pt-24 md:block hidden">
        <KeyVisual goToCampaign={goToCampaign} />

        <HowToPlay />

        <Content />
        <Sports />

        <Campaign />

        <Reward openImportantNotes={openImportantNotes} />
        <Footer />
      </main>
    </>
  );
};
