export const Sports = () => (
  <div className="scroll-container">
    <div className="scroll-content">
      {Array.from({ length: 48 }, (_, i) => {
        const num = (i % 16) + 1;
        const formattedNum = num.toString().padStart(3, "0");
        return (
          <img
            key={formattedNum}
            src={`/sports/${formattedNum}.svg`}
            alt={formattedNum}
            className="w-6 h-6 p-4 box-content mx-1 bg-[#0B0B0B] border border-[#313131]"
          />
        );
      })}
    </div>
  </div>
);
