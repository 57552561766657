import { useUserCount } from "@/hooks/useUserCount";
import { MainButton } from "../shared/MainButton";

interface Props {
  goToCampaign: () => void;
}

export const KeyVisual: React.FC<Props> = ({ goToCampaign }) => {
  const { userCount } = useUserCount();

  return (
    <div className="w-full bg-[url('/pc-mainvisual.png')] bg-cover bg-center bg-no-repeat">
      <div className="max-w-[1040px] mx-auto flex justify-between">
        <div className="my-[90px]">
          <p className="text-white text-[50px] leading-[54px] my-[30px]">
            Enter the exciting world
            <br />
            of fantasy sports
            <br />
            with your thumb
          </p>
          <a href="https://t.me/PreThumbabot">
            <MainButton width="420px">
              <img src="/telegram-black.svg" alt="Play" className="h-5" />
              "Pre-registration now open!!"​
            </MainButton>
          </a>
          <button
            className="text-[#989898] rounded-full text-[16px] leading-[35px] w-40 bg-[#2A2A2A] mt-[10px]"
            type="button"
            onClick={goToCampaign}
          >
            ※Details
          </button>
          {userCount != null && (
            <>
              <p className="text-[#989898] text-[14px] leading-[14px] mt-4 mb-1">Pre-registration users​</p>
              <p className="text-white text-[46px] leading-[46px]">{userCount.toLocaleString()}</p>
            </>
          )}
        </div>
        <img src="/pc-kv.png" alt="KV" className="h-[560px]" />
      </div>
    </div>
  );
};
