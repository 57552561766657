import { MobileLayout } from "@/components/mobile";
import { PCLayout } from "@/components/pc";
import { ImportantNotes } from "@/components/shared/ImportantNotes";
import { useState } from "react";
import ReactGA from "react-ga4";

export const HomePage = () => {
  ReactGA.send({ hitType: "pageview", page: "/" });
  const [showImportantNotes, setShowImportantNotes] = useState(false);

  return (
    <>
      <MobileLayout openImportantNotes={() => setShowImportantNotes(true)} />
      <PCLayout openImportantNotes={() => setShowImportantNotes(true)} />
      {showImportantNotes && <ImportantNotes closeModal={() => setShowImportantNotes(false)} />}
    </>
  );
};
