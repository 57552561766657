export const Content = () => (
  <>
    <div className="w-full h-full text-center my-8 max-w-[1300px] px-[30px] mx-auto">
      <p className="text-white text-[32px] leading-[40px]">Contents</p>
    </div>

    <div className="w-full max-w-[1300px] px-[30px] mx-auto flex flex-wrap justify-between gap-4 my-8">
      <img src="/contents/content01.png" alt="soccer" className="w-[calc(25%-1rem)] object-cover" />
      <img src="/contents/content02.png" alt="nba" className="w-[calc(25%-1rem)] object-cover" />
      <img src="/contents/content03.png" alt="MLB" className="w-[calc(25%-1rem)] object-cover" />
      <img src="/contents/content04.png" alt="F1" className="w-[calc(25%-1rem)] object-cover" />
    </div>
  </>
);
